import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { createApp } from "./lib/client"
import * as Quill from "quill"
import { getFirestore, collection, addDoc, onSnapshot, doc, getDoc, getDocs, setDoc, Timestamp, query, orderBy } from "firebase/firestore"
import Swal from "sweetalert2"
import dayjs from "dayjs"

const app = createApp()
const auth = getAuth(app)

// Get URL
const theUrl = window.location.pathname
console.log(theUrl)


function redirectSignOut() {
  window.location.href = "/login"
}

function redirect404() {
  window.location.href = "/404"
}

function redirectAlreadySignedIn() {
  window.location.href = "/adminhome"
}

if (theUrl == "/adminhome") {
  document.getElementById("userLogoutNow").addEventListener("click", async function () {
    signOut(auth)
      .then(() => {
        // Sign-out successfull
        redirectSignOut()
      })
      .catch((error) => {
        // An error happened.
      })
  })
}
if (theUrl == "/adminhome/entries") {
  document.getElementById("userLogoutNow").addEventListener("click", async function () {
    signOut(auth)
      .then(() => {
        // Sign-out successfull
        redirectSignOut()
      })
      .catch((error) => {
        // An error happened.
      })
  })
}

if (theUrl == "/adminhome/blogs") {
  document.getElementById("userLogoutNow").addEventListener("click", async function () {
    signOut(auth)
      .then(() => {
        // Sign-out successfull
        redirectSignOut()
      })
      .catch((error) => {
        // An error happened.
      })
  })
}
if (theUrl == "/account-collections") {
  document.getElementById("userLogoutNow").addEventListener("click", async function () {
    signOut(auth)
      .then(() => {
        // Sign-out successfull
        redirectSignOut()
      })
      .catch((error) => {
        // An error happened.
      })
  })
}

if (theUrl == "/login") {
  console.log("test console")
  document.getElementById("ayenlogin")?.addEventListener("click", async function () {
    const email = document.getElementById("email").value
    const password = document.getElementById("password").value
    console.log("test console")

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        var user = userCredential.user
        console.log("Signed In")
        //..
        window.location.href = "/adminhome"
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log("Signin Failed")
        console.log("Error:" + errorMessage)
      })
  })
}

onAuthStateChanged(auth, (user) => {
  if (user != null) {
    console.log("Signed In")
    const uid = user.uid
    console.log(uid)
    if (theUrl == "/login") {
      redirectAlreadySignedIn()
    }
  } else {
    console.log("Signed Out")
    //User is signed out

    const regex = /^\/adminhome(\/.*)?$/
    const isMatch = regex.test(window.location.pathname)
    console.log(isMatch)
    if (isMatch) {
      console.log("Signed Out Ayen Tambok")
      redirect404()
    }
    if (theUrl == "/account-collections") {
      redirect404()
    }
    if (theUrl == "/account-collections.html") {
      redirect404()
    }
    if (theUrl == "/adminhome/entries") {
      redirect404()
    }
    
  }
})

const db = getFirestore(app)

let title = ""
let content = ""
let editor = null

if (document.getElementById("editor")) {
  editor = new Quill("#editor", {
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // Basic text formatting
        ["blockquote", "code-block"], // Block-level formatting
        [{ header: 1 }, { header: 2 }], // Headings
        [{ list: "ordered" }, { list: "bullet" }], // Lists
        [{ script: "sub" }, { script: "super" }], // Subscript and superscript
        [{ indent: "-1" }, { indent: "+1" }], // Indentation
        [{ direction: "rtl" }], // Text direction
        [{ size: ["small", false, "large", "huge"] }], // Font size
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // Heading levels
        [{ color: [] }, { background: [] }], // Text and background color
        [{ font: [] }], // Font family
        [{ align: [] }], // Text alignment
        ["clean"], // Remove formatting
        ["link", "image"], // Insert link, image, or video
      ],
    },
    placeholder: "Write a blog...",
    theme: "snow", // or 'bubble'
  })

  editor.on("text-change", function (delta, oldDelta, source) {
    if (editor.getText().trim().length > 0) {
      content = editor.root.innerHTML
    } else {
      content = editor.root.innerHTML
    }
  })
}

const titleInput = document.getElementById("blog-title-input")
if (titleInput) {
  titleInput.addEventListener("change", (e) => {
    const value = e.target.value
    if (value.trim().length > 0) {
      title = value.trim()
    } else {
      title = value.trim()
    }
  })
}

const saveButton = document.getElementById("blog-save")
// If save button exist
if (saveButton) {
  saveButton.addEventListener("click", async () => {
    saveButton.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
    <span class="visually-hidden">Loading...</span>
  </span>`
    const loadingElement = saveButton.lastChild

    saveButton.classList.toggle("pe-none")
    const isWritten = title && content
    if (!isWritten) {
      Swal.fire({
        title: "Empty Title or Content",
        text: "Please write something before saving",
        icon: "error",
        confirmButtonText: "Close",
      })
    } else {
      await addDoc(collection(db, "blogs"), { content, title, createdAt: dayjs().format("MMMM D, YYYY") })
      editor.root.innerHTML = ""
      if (titleInput) titleInput.value = ""

      Swal.fire({
        title: "Blog Post Saved",
        text: "You can view it in the blogs page",
        icon: "success",
        confirmButtonText: "Ok",
      })
    }

    saveButton.classList.toggle("pe-none")
    saveButton.removeChild(loadingElement)
    saveButton.innerHTML = "Save"
  })
}

const subsListItem = (email, createdAt, id) => {
  let trimmedContent = content
  trimmedContent = trimmedContent.replace(/<img[^>]*>/gi, "")
  if (trimmedContent.length > 100) {
    trimmedContent = trimmedContent.substring(0, 300) + "..."
  }
  return `
                        <tr>
                        <td>${email}</td>
                        <td>${createdAt}</td>
                        </tr>
  `
}

const collectionRef2 = collection(db, "emails")
onSnapshot(collectionRef2, (snapshot) => {
  const subsPosts = []
  snapshot.forEach((doc) => {
    const item = subsListItem(doc.data().email, doc.data().createdAt, doc.id)
    subsPosts.push(item)
  })

  const subsList = document.getElementById("subs-list")
  if (subsList) {
    subsList.innerHTML = subsPosts.join("")
  }
})


const blogListItem = (title, content, createdAt, id) => {
  let trimmedContent = content
  trimmedContent = trimmedContent.replace(/<img[^>]*>/gi, "")
  if (trimmedContent.length > 100) {
    trimmedContent = trimmedContent.substring(0, 300) + "..."
  }
  return `
  <article class="card border-0 shadow-sm overflow-hidden mb-4">
  <div class="row g-0">

    <div class="col-sm-8">
      <div class="card-body">
        <div class="d-flex align-items-center mb-2">
          <a href="#" class="badge fs-sm text-nav bg-secondary text-decoration-none">Created At</a>
          <span class="fs-sm text-muted border-start ps-3 ms-3">${createdAt}</span>
        </div>
        <h3 class="h4">
          <a href="/blogs/blog-post?id=${id}">${title}</a>
        </h3>
      </div>
      <div class="card-body d-flex align-items-center mb-3">
        <p>
          ${trimmedContent}
        </p>
        <hr class="my-4" />
      </div>
    </div>
  </div>
</article>
  `
}


const collectionRef3 = collection(db, "blogs")
onSnapshot(collectionRef3, (snapshot) => {
  const blgPosts2 = []
  snapshot.forEach((doc) => {
    const item = blogListItem2(doc.data().title, doc.data().content, doc.data().createdAt, doc.id)
    blgPosts2.push(item)
  })

  const blgList2 = document.getElementById("blog-list2")
  if (blgList2) {
    blgList2.innerHTML = blgPosts2.join("")
  }
})

const reports1ListItem = (title, fileLink, desc, img) =>{
  return `
  <!-- Blog item -->
          <article class="card border-0 shadow-sm overflow-hidden mb-4">
            <div class="row g-0">
              <div class="col-sm-4 position-relative bg-repeat-0 bg-size-cover" style="background-image: url(${img}); min-height: 15rem;">

              </div>
              <div class="col-sm-8">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-3">
                    <span class="fs-sm text-muted ps-3 ms-3"></span>
                  </div>
                  <h3 class="h4 text-primary">
                    <h3 class="h4 ">
                    <a href="#">${title}</a>
                  </h3>
                  <p>${desc}</p>
                  <hr class="my-4">
                  <div class="d-flex align-items-center justify-content-between">
                   
                    <div class="d-flex align-items-center text-muted">
                      <div class="d-flex align-items-center">
                        <span class="fs-sm">
                        <a target="_blank" href="${fileLink}">
                          <i class="bx bx-download fs-lg me-1"></i>
                        Download PDF
                        </a></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
  `
}
const reports2ListItem = (title, fileLink, desc, img) =>{
  return `
  <!-- Blog item -->
          <article class="card border-0 shadow-sm overflow-hidden mb-4">
            <div class="row g-0">
              <div class="col-sm-4 position-relative bg-repeat-0 bg-size-cover" style="background-image: url(${img}); min-height: 15rem;">

              </div>
              <div class="col-sm-8">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-3">
                    <span class="fs-sm text-muted ps-3 ms-3"></span>
                  </div>
                  <h3 class="h4 text-primary">
                    <h3 class="h4 ">
                    <a href="#">${title}</a>
                  </h3>
                  <p>${desc}</p>
                  <hr class="my-4">
                  <div class="d-flex align-items-center justify-content-between">
                   
                    <div class="d-flex align-items-center text-muted">
                      <div class="d-flex align-items-center">
                        <span class="fs-sm">
                        <a target="_blank" href="${fileLink}">
                          <i class="bx bx-download fs-lg me-1"></i>
                        Download PDF
                        </a></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
  `
}

const blogListItem2 = (title, content, createdAt, id) => {
  let trimmedContent = content
  let trimmedTitle = title
  trimmedContent = trimmedContent.replace(/<img[^>]*>/gi, "")
  if (trimmedContent.length > 100) {
    trimmedContent = trimmedContent.substring(0, 280) + "..."
  }
  if (trimmedTitle.length > 40){
    trimmedTitle = trimmedTitle.substring(0, 35) + "..."
  }

  return `

<article class="swiper-slide h-auto pb-3 card w-50">

  <div class="card-header">
    <div class="d-flex justify-content-between mb-1">
    </div>
    <h3 class="h4 mb-0">
      <a class="" href="/blogs/blog-post?id=${id}">${trimmedTitle}</a>
    </h3>
  </div>

  <div class="card-body">
    <p class="mb-2">${trimmedContent}</p>
  </div>

  <div class="card-footer fs-sm text-muted">
    <div class="d-flex align-items-center text-muted">
      <div class="fs-sm pe-3 me-3">${createdAt}</div>
    </div>
  </div>

</article>
  `
}

const entryListitem = (fname, emailadd, msg, createdAt, id) => {
  return `
        <a  class="list-group-item list-group-item-action flex-column align-items-start py-3 mt-1 mb-2">
          <div class="d-flex flex-wrap w-100 justify-content-between bottom">
            <h6>${fname} <br/>
            <small class="text-italic text-muted">${emailadd}</small></h6>
            <small class="text-muted mb-2">${createdAt}</small>
          </div>
          <p class="fs-sm fw-normal text-body">${msg}</p>
        </a>
  `
}


const collectionRefCF = collection(db, "submissions")
onSnapshot(collectionRefCF, (snapshot) => {
  const submissions = []
  snapshot.forEach((doc) => {
    const entry = entryListitem(doc.data().fullname, doc.data().email, doc.data().message, doc.data().createdAt, doc.id)
    submissions.push(entry)
  })

  const entryList = document.getElementById("submissions-list")
  if (entryList) {
    entryList.innerHTML = submissions.join("")
  }
})






const collectionRef = collection(db, "blogs")
onSnapshot(collectionRef, (snapshot) => {
  const blogPosts = []
  snapshot.forEach((doc) => {
    const item = blogListItem(doc.data().title, doc.data().content, doc.data().createdAt, doc.id)
    blogPosts.push(item)
  })

  const blogList = document.getElementById("blog-list")
  if (blogList) {
    blogList.innerHTML = blogPosts.join("")
  }
})

const ReportscollectionRef = collection(db, "reports")
let queryAyen = query(ReportscollectionRef, orderBy("year", "desc"))
onSnapshot(queryAyen, (snapshot) => {
  const reports1Posts = []
  snapshot.forEach((doc) => {
    const item = reports1ListItem(doc.data().title, doc.data().fileLink, doc.data().desc, doc.data().img)
    reports1Posts.push(item)
  })

  const reports1List = document.getElementById("reports1List")
  if (reports1List){
    reports1List.innerHTML = reports1Posts.join("")
  }
})

const ReportscollectionRef2 = collection(db, "reports2")
let queryChing = query(ReportscollectionRef2, orderBy("ysort", "desc"));
onSnapshot(queryChing, (snapshot) => {
  const reports2Posts = []
  snapshot.forEach((doc) => {
    const item = reports2ListItem(doc.data().title, doc.data().fileLink, doc.data().desc, doc.data().img)
    reports2Posts.push(item)
  })

  const reports2List = document.getElementById("collapseExample")
  if (reports2List){
    reports2List.innerHTML = reports2Posts.join("")
  }
})

const getDocData = async (id) => {
  const docRef = doc(db, "blogs", id)
  const docSnap = await getDoc(docRef)
  return docSnap.data()
}

const blogPostContent = document.getElementById("blog-post-content")
if (blogPostContent) {
  const queryString = window.location.search

  // Split the query string into an array of parameter/value pairs
  const params = queryString.substring(1).split("&")

  // Loop through the parameter/value pairs and create an object with the parameter names as keys and the values as values
  const queryParams = {}
  for (let i = 0; i < params.length; i++) {
    const param = params[i].split("=")
    const key = decodeURIComponent(param[0])
    const value = decodeURIComponent(param[1])
    queryParams[key] = value
  }

  if (queryParams.id) {
    const data = await getDocData(queryParams.id)
    blogPostContent.innerHTML = data.content

    const blogPostTitle = document.getElementById("blog-post-title")
    blogPostTitle.innerHTML = data.title

    const blogPostTitleBread = document.getElementById("blog-post-title-bread")
    blogPostTitleBread.innerHTML = data.title

    const blogHeadTitle = document.getElementById("blog-head-title")
    blogHeadTitle.innerHTML = "Blog | " + data.title
  }
}

document.addEventListener("DOMContentLoaded", async () => {
  const imageUpload = document.getElementById("image-upload-1")
  const imagePreview = document.getElementById("image-preview-1")
  const errorMessage = document.getElementById("error-message-1")

  const maxSizeInMB = 2 // Set the maximum allowed size in MB
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024

  if (imageUpload && imagePreview) {
    imageUpload.addEventListener("change", (event) => {
      const file = event.target.files[0]

      if (file && file.type.startsWith("image/")) {
        if (file.size > maxSizeInBytes) {
          errorMessage.textContent = `Image size must be under ${maxSizeInMB} MB.`
          errorMessage.style.display = "block"
          imagePreview.style.display = "none"
          imagePreview.src = ""
        } else {
          const reader = new FileReader()

          reader.onload = async (e) => {
            imagePreview.src = e.target.result
            imagePreview.style.display = "block"
            errorMessage.style.display = "none"

            await setDoc(doc(db, "media", "image-1"), {
              base64: e.target.result,
              createdAt: dayjs().format("MMMM D, YYYY"),
            })

            Swal.fire({
              title: "Uploaded Image Successfully",
              text: "Image is saved in the database",
              icon: "success",
              confirmButtonText: "Close",
            })
          }

          reader.readAsDataURL(file)
        }
      } else {
        errorMessage.style.display = "none"
        imagePreview.style.display = "none"
        imagePreview.src = ""
      }
    })
  }
})


document.addEventListener("DOMContentLoaded", async () => {
  const imageUpload = document.getElementById("image-upload-h")
  const imagePreview = document.getElementById("image-preview-h")
  const errorMessage = document.getElementById("error-message-h")

  const maxSizeInMB = 2 // Set the maximum allowed size in MB
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024

  if (imageUpload && imagePreview) {
    imageUpload.addEventListener("change", (event) => {
      const file = event.target.files[0]

      if (file && file.type.startsWith("image/")) {
        if (file.size > maxSizeInBytes) {
          errorMessage.textContent = `Image size must be under ${maxSizeInMB} MB.`
          errorMessage.style.display = "block"
          imagePreview.style.display = "none"
          imagePreview.src = ""
        } else {
          const reader = new FileReader()

          reader.onload = async (e) => {
            imagePreview.src = e.target.result
            imagePreview.style.display = "block"
            errorMessage.style.display = "none"

            await setDoc(doc(db, "media", "image-h"), {
              base64: e.target.result,
              createdAt: dayjs().format("MMMM D, YYYY"),
            })

            Swal.fire({
              title: "Uploaded Image Successfully",
              text: "Image is saved in the database",
              icon: "success",
              confirmButtonText: "Close",
            })
          }

          reader.readAsDataURL(file)
        }
      } else {
        errorMessage.style.display = "none"
        imagePreview.style.display = "none"
        imagePreview.src = ""
      }
    })
  }
})

document.addEventListener("DOMContentLoaded", () => {
  const imageUpload = document.getElementById("image-upload-2")
  const imagePreview = document.getElementById("image-preview-2")
  const errorMessage = document.getElementById("error-message-2")

  const maxSizeInMB = 2 // Set the maximum allowed size in MB
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024

  if (imageUpload && imagePreview) {
    imageUpload.addEventListener("change", (event) => {
      const file = event.target.files[0]

      if (file && file.type.startsWith("image/")) {
        if (file.size > maxSizeInBytes) {
          errorMessage.textContent = `Image size must be under ${maxSizeInMB} MB.`
          errorMessage.style.display = "block"
          imagePreview.style.display = "none"
          imagePreview.src = ""
        } else {
          const reader = new FileReader()

          reader.onload = async (e) => {
            imagePreview.src = e.target.result
            imagePreview.style.display = "block"
            errorMessage.style.display = "none"

            await setDoc(doc(db, "media", "image-2"), {
              base64: e.target.result,
              createdAt: dayjs().format("MMMM D, YYYY"),
            })

            Swal.fire({
              title: "Uploaded Image Successfully",
              text: "Image is saved in the database",
              icon: "success",
              confirmButtonText: "Close",
            })
          }

          reader.readAsDataURL(file)
        }
      } else {
        errorMessage.style.display = "none"
        imagePreview.style.display = "none"
        imagePreview.src = ""
      }
    })
  }
})

document.addEventListener("DOMContentLoaded", () => {
  const imageUpload = document.getElementById("image-upload-3")
  const imagePreview = document.getElementById("image-preview-3")
  const errorMessage = document.getElementById("error-message-3")

  const maxSizeInMB = 2 // Set the maximum allowed size in MB
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024

  if (imageUpload && imagePreview) {
    imageUpload.addEventListener("change", (event) => {
      const file = event.target.files[0]

      if (file && file.type.startsWith("image/")) {
        if (file.size > maxSizeInBytes) {
          errorMessage.textContent = `Image size must be under ${maxSizeInMB} MB.`
          errorMessage.style.display = "block"
          imagePreview.style.display = "none"
          imagePreview.src = ""
        } else {
          const reader = new FileReader()

          reader.onload = async (e) => {
            imagePreview.src = e.target.result
            imagePreview.style.display = "block"
            errorMessage.style.display = "none"

            await setDoc(doc(db, "media", "image-3"), {
              base64: e.target.result,
              createdAt: dayjs().format("MMMM D, YYYY"),
            })

            Swal.fire({
              title: "Uploaded Image Successfully",
              text: "Image is saved in the database",
              icon: "success",
              confirmButtonText: "Close",
            })
          }

          reader.readAsDataURL(file)
        }
      } else {
        errorMessage.style.display = "none"
        imagePreview.style.display = "none"
        imagePreview.src = ""
      }
    })
  }
})

const getHomepageImages = async () => {
  const collectionRef = collection(db, "media")
  const snapshot = await getDocs(collectionRef)

  let docData = []
  snapshot.forEach((doc) => {
    docData.push({ ...doc.data(), id: doc.id })
  })

  return docData
}
const homePageImage1 = document.getElementById("homepage-image-1")
const homePageImage2 = document.getElementById("homepage-image-2")
const homePageImage3 = document.getElementById("homepage-image-3")
const homePageImageh = document.getElementById("homepage-image-h")

if (homePageImage1) {
  const images = await getHomepageImages()
  homePageImage1.src = images.find(({ id }) => id === "image-1").base64
  homePageImage2.src = images.find(({ id }) => id === "image-2").base64
  homePageImage3.src = images.find(({ id }) => id === "image-3").base64
  homePageImageh.innerHTML = `
  <div
          class="jarallax-img"
          style="background-image: url(${images.find(({ id }) => id === "image-h").base64})"
        ></div>
  `
}

const subscriptionForm = document.getElementById("email-subscription-form")
if (subscriptionForm) {
  subscriptionForm.addEventListener("submit", async (e) => {
    e.preventDefault()
    const email = document.getElementById("email-subscription-input").value

    if (email.trim().length === 0) {
      Swal.fire({
        title: "Empty Email",
        text: "Please enter your email",
        icon: "error",
        confirmButtonText: "Close",
      })
      return
    }

    const collectionRef = collection(db, "emails")
    await addDoc(collectionRef, { email, createdAt: dayjs().format("MMMM D, YYYY") })

    Swal.fire({
      title: "Thank you for subscribing to Ocean Conversations!",
      text: "You will now receive updates on critical ocean conservation stories and trends from the Indo-Pacific region, straight to your inbox every two weeks.",
      icon: "success",
      confirmButtonText: "Close",
    })

    document.getElementById("email-subscription-input").value = ""
    subscriptionForm.classList.replace("was-validated", "needs-validation")
  })
}

const contactUsForm = document.getElementById("contact-us-form")
if (contactUsForm) {
  contactUsForm.addEventListener("submit", async (e) => {
    e.preventDefault()
    const fullname = document.getElementById("entry-fullname").value
    const email = document.getElementById("entry-email").value
    const message = document.getElementById("entry-message").value
    //1
    if (fullname.trim().length === 0) {
      Swal.fire({
        title: "Full name empty",
        text: "Please enter your full name",
        icon: "error",
        confirmButtonText: "Close",
      })
      return
    }
    if (email.trim().length === 0) {
      Swal.fire({
        title: "Email empty",
        text: "Please enter your email address",
        icon: "error",
        confirmButtonText: "Close",
      })
      return
    }
    if (message.trim().length === 0) {
      Swal.fire({
        title: "Message empty",
        text: "Please add a message",
        icon: "error",
        confirmButtonText: "Close",
      })
      return
    }

        //2
    const collectionRef = collection(db, "submissions")
    await addDoc(collectionRef, { fullname, email, message, createdAt: dayjs().format("MMMM D, YYYY"), timestamp: Timestamp.now() })

    Swal.fire({
      title: "Thank you for contacting us.",
      text: "We appreciate your interest and will get back to you promptly. We look forward to connecting with you soon.",
      icon: "success",
      confirmButton: "Close",
    })
    document.getElementById("entry-message").value = ""
    contactUsForm.classList.replace("was-validated", "needs-validation")

  })
}

