import { initializeApp } from "firebase/app"

let app = null
export const createApp = () => {
  if (app) return app
  const firebaseConfig = {
    apiKey: "AIzaSyAzyHk7ernBe4tlmDwtphFsXe2L_yB99Wo",
    authDomain: "bga-cs.firebaseapp.com",
    projectId: "bga-cs",
    storageBucket: "bga-cs.appspot.com",
    messagingSenderId: "416069128777",
    appId: "1:416069128777:web:aedd0b43d543a6b6ebe598",
    measurementId: "G-B8FV5HE3CD",
  }

  // Initialize Firebase
  app = initializeApp(firebaseConfig)
  return app
}
